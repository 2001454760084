<template>
  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <div v-else-if="hasPost" class="my-24 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto">
      <section>
        <div class="mb-16">
          <div class="flex justify-between">
            <div>
              <h1 class="text-lg leading-6 font-medium text-gray-900">
                Edit post
              </h1>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Editing a published blog post
              </p>
            </div>
            <BaseButton
              class="bg-red-50 text-red-700 border-red-300 hover:bg-red-200"
              @click.prevent="toggleDeletePostDialog"
            >
              Delete Post
            </BaseButton>
          </div>
        </div>
        <post-form
          @save-data="saveData"
          mode="update"
          :id="id"
          :title="title"
          :body="body"
          :description="description"
          :category-id="categoryId"
          :reading-time="readingTime"
          :author="author"
          :date-posted="datePosted"
        ></post-form>
      </section>
    </div>
    <BaseDialog :show="isDeletePostDialogOpen" :fixed="false" enableFocusTrap>
      <template #header>
        <header
          class="p-6 font-semibold text-lg bg-red-100 text-red-900 text-center"
        >
          <p>Are you sure you want to remove this blog post?</p>
        </header>
        <div v-if="deletePostError" class="text-red-700 text-center mt-4">
          There was a problem while deleting the post.
        </div>
      </template>
      <template #actions>
        <footer>
          <div class="space-x-3">
            <BaseButton
              class="hover:bg-gray-50"
              :disabled="deletePostLoading"
              @click.prevent="toggleDeletePostDialog"
            >
              <template v-if="deletePostSuccess"> Close </template>
              <template v-else> Cancel </template>
            </BaseButton>
            <BaseButton
              class="bg-red-600 text-red-100 hover:bg-red-700"
              :disabled="deletePostLoading"
              @click.prevent="initDeletePost"
              v-if="!deletePostSuccess"
              >Remove</BaseButton
            >
          </div>
        </footer>
      </template>
    </BaseDialog>
  </div>
  <h3 v-else>Post not found.</h3>
</template>

<script>
import { getCurrentInstance, watch } from 'vue'
import PostForm from '../../components/blog/PostForm.vue'
import { useToggle } from '@/composables'
import { useMutation } from 'vue-query'
import { deletePost } from '@/api/blogApi'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  props: ['slug'],
  components: {
    PostForm,
  },
  setup() {
    const vm = getCurrentInstance()
    const { state: isDeletePostDialogOpen, toggle: toggleDeletePostDialog } =
      useToggle()
    const store = useStore()
    const router = useRouter()
    const {
      isLoading: deletePostLoading,
      isSuccess: deletePostSuccess,
      isError: deletePostError,
      mutate: initDeletePost,
      reset: resetDeletePost,
    } = useMutation('deletePost', () => deletePost(vm.data.currentPost.id), {
      onSuccess: () => {
        store.commit('blog/removePost', vm.data.currentPost.id)
        router.push('/')
      },
    })

    watch(isDeletePostDialogOpen, isOpen => {
      if (!isOpen) resetDeletePost.value()
    })

    return {
      isDeletePostDialogOpen,
      toggleDeletePostDialog,
      deletePostLoading,
      deletePostSuccess,
      deletePostError,
      initDeletePost,
    }
  },
  data() {
    return {
      isLoading: false,
      currentPost: null,
      error: null,
    }
  },
  computed: {
    hasPost() {
      return !this.isLoading && this.currentPost !== null
    },
    id() {
      return this.currentPost.id
    },
    title() {
      return this.currentPost.title
    },
    description() {
      return this.currentPost.description
    },
    categoryId() {
      return this.currentPost.categoryId
    },
    categoryName() {
      return this.currentPost.category?.name
    },
    author() {
      return this.currentPost.author
    },
    datePosted() {
      return this.currentPost.datePosted
    },
    readingTime() {
      return this.currentPost.readingTime
    },
    body() {
      return this.currentPost.body
    },
    dateReadable() {
      const date = new Date(this.currentPost.datePosted)
      const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      return dateTimeFormat.format(date)
    },
  },
  methods: {
    async loadPost(refresh = false) {
      this.isLoading = true
      try {
        await this.$store.dispatch('blog/loadPosts', { forceRefresh: refresh })
      } catch (error) {
        this.error =
          error.message || 'Something went wrong: failed to load posts'
      }
      this.currentPost = this.$store.getters['blog/posts'].find(
        post => post.slug === this.slug
      )
      this.isLoading = false
    },
    /*async updateStore(data) {
				this.isLoading = true;
				try {
					await this.$store.dispatch('blog/updatePost', data);
				} catch (error) {
					this.error = error.message || 'Something went wrong: failed to save post';
				}
			},
			saveData(data) {
				this.updateStore(data).then(this.$router.replace('/blog'));
			},*/
    async saveData(data) {
      const { slug } = await this.$store.dispatch('blog/updatePost', data)
      this.$router.push(`/blog/${slug}`)
    },
    handleError() {
      this.error = null
    },
  },
  created() {
    this.loadPost()
  },
}
</script>
